import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Login from './components/Login/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import EntityPicker from './components/EntityPicker/EntityPicker';
import Loader from './components/Loader/Loader';

const App = () => {
    return (
        <Router>
            <div className='App'>
                <Switch>
                    <Route path='/login'>
                        <Layout>
                            <Loader/>
                            <Login />
                        </Layout>
                    </Route>
                    <PrivateRoute path='/'>
                        <Layout>
                            <Loader/>
                            <EntityPicker />
                        </Layout>
                    </PrivateRoute>
                </Switch>
            </div>
        </Router>
    )
}

export default App;