import {Fragment, useState, useEffect} from 'react';
import './Modal.scss';
import Button from "../Button/Button";
import useHttp from "../../hooks/useHttp";

const Modal = (props) => {
    const {text, displayModal} = props;

    const {
        data,
        handleNexusRequest, 
    } = useHttp();

    const [showModal, setShowModal] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [entity, setEntity] = useState("");

    const closeModal = () => {
        setShowModal(false);
    };

    const downloadReport = async () => {
        await handleNexusRequest('get-report', {entity})
    }

    useEffect(() => {
        if (data) {
            window.open(data.reportUrl, '_blank', 'noopener,noreferrer');
        }
    }, [data])

    useEffect(() => {
        setShowModal(displayModal);
        try {
            const textObject = JSON.parse(text);
            if (textObject.generatedReportName && textObject.entity) {
                setEntity(textObject.entity);
                setShowButton(true)
            }
        } catch (e) {

        }
    }, [displayModal])

    return (
        <Fragment>
            {showModal && <div className="modal">
                <div className="content">
                    <span className="close" onClick={closeModal}>Close</span>
                    <textarea defaultValue={text}/>
                    {showButton && <Button text="download report" action={downloadReport}/>}
                </div>
            </div>}
        </Fragment>
    )
};

export default Modal;