import {useEffect, useState} from 'react';
import './Logout.scss';

const Logout = () => {
    const [token, setToken] = useState('');
    const [isProduction, setIsProduction] = useState('');

    useEffect(() => {
        setToken(sessionStorage.getItem('sessionToken'));
        setIsProduction(sessionStorage.getItem('isProduction'));
    }, [])

    const logoutAction = () => {
        sessionStorage.removeItem('sessionToken');
        sessionStorage.removeItem('isProduction');
        window.location.href = '/login';
    }

    return token && <div className="logout" onClick={logoutAction}>
            <span>{isProduction === 'true' ? 'Production' : 'Dev'} / </span>
            <span>Logout</span>
        </div>
}

export default Logout;