import './Switch.scss';

const Switch = (props) => {
    const {text, action, id, defaultValue} = props;

    return (
        <div className="switchWrapper" data-testid="switch-wrapper">
            <input type="checkbox" className="toggle-switch" id={id} onChange={action} defaultChecked={defaultValue} data-testid="switch"/>
            <label htmlFor="toggle">{text}</label>
        </div>
    )
};

export default Switch;