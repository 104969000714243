import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
    const token = sessionStorage.getItem('sessionToken');
    return (
        <Route {...rest} render={({ location }) =>
            token ? (children): (<Redirect to={{ pathname: "/login", state: { from: location }}}/>)
        }
        />
    );
}

export default PrivateRoute;
