import React, {useState} from 'react';
import Button from '../Button/Button';
import {entityTemplatesConfig} from "../../config/entityTemplatesConfig";
import './RawFormInput.scss';

const RawFormInput = (props) => {
    const {REACT_APP_FILES_STORAGE_URL} = process.env;
    const {sendRawDataAction, entity} = props;

    const [inputData, setInputData] = useState('');

    const onChange = (event) => {
        setInputData(event.target.value);
    }

    const handleClick = () => {
        let localData;
        if (Array.isArray(JSON.parse(inputData))) {
            localData = {};
            localData[entity] = JSON.parse(inputData);
        } else {
            localData = JSON.parse(inputData);
        }
        sendRawDataAction(localData);
    }

    return (
        <div className="rawFormInput" data-testid="rawFormInput">
            <p>
                Need help with the entry format? Download the {entityTemplatesConfig[entity]['json'].map(item =>
                <a
                    key={`${item.fileName}.json`}
                    href={`${REACT_APP_FILES_STORAGE_URL}/${item.fileName}.json`}
                    target="_blank"
                    rel="noreferrer">
                    <span className="templateName">{item.displayName}</span>
                </a>)}
            </p>
            <textarea onChange={onChange}/>
            <Button text="Submit" action={handleClick} />
        </div>
    )
}

export default RawFormInput;