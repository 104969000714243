import {Fragment} from 'react';
import './Layout.scss';
import Logout from "../Logout/Logout";

const layout = (props) => (
    <Fragment>
        <header>
            <div className="left">Nexus Panel</div>
            <div className="right"><Logout/></div>
        </header>
        <main>
            {props.children}
        </main>
    </Fragment>
);

export default layout;