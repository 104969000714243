// commented out properties currently don"t have change log support
const changeLogServices = [
    "ad-profile",
    "ad-quality-rule",
    "broker",
    "budget-splitter",
    "campaign",
    "creative",
    "deal",
    "deal-buyer-setting",
    // "deal-list",
    "domain-list",
    "insertion-order",
    // "inventory-list",
    "ip-range-list",
    "line-item",
    "native-layout",
    "partner-fee",
    "placement",
    // "postal-code-list",
    "profile",
    "publisher",
    // "registration-form",
    "site",
    "thirdparty-pixel",
    // "universal-pixel"
];

const entityConfiguration = {
    "deal": {
        get: ["ids", "excludeQuery", "includeQuery"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"],
        delete: ["ids"]
    },
    "keyvalue": {
      delete: ["upload"]
    },
    "placement": {
        get: ["ids", "excludeQuery", "includeQuery", "publisher_id", "site_id"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"]
    },
    "publisher": {
        get: ["ids", "excludeQuery", "includeQuery"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"],
    },
    "site": {
        get: ["ids", "excludeQuery", "includeQuery", "publisher_id"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"]
    },
    "creative": {
        get: ["ids", "excludeQuery", "includeQuery", "advertiser_id"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"]
    },
    "profile": {
        get: ["ids", "excludeQuery", "includeQuery"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"],
    },
    "content-category": {
        get: ["ids", "excludeQuery", "includeQuery"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"],
    },
    "placement-set": {
        get: ["ids", "excludeQuery", "includeQuery", "publisher_id"]
    },
    "media-subtype": {
        get: ["ids", "excludeQuery", "includeQuery"]
    },
    "line-item": {
        get: ["ids", "excludeQuery", "includeQuery", "advertiser_id", "advertiser_code"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"]
    },
    "campaign": {
        get: ["ids", "excludeQuery", "includeQuery", "advertiser_id"],
        create: ["blob", "uploader"],
        update: ["blob", "upload"]
    },
    "change-log": {
        get: [
            {
                name: "service",
                type: "select",
                values: changeLogServices
            },
            "resource_id",
            "page"
        ]
    }
}

export {entityConfiguration};