import React, {useEffect, useState} from 'react';
import useHttp from '../../hooks/useHttp';
import {withRouter} from 'react-router-dom';
import './Login.scss';
import ErrorNotification from '../Notifications/ErrorNotification/ErrorNotification';
import Button from '../Button/Button';
import {useInput} from '../../hooks/useInput';
import Switch from '../Switch/Switch';

const Login = (props) => {
    const { value:username, bind:bindUsername, reset:resetUsername } = useInput('');
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');
    const {
        error,
        data,
        handleNexusRequest,
    } = useHttp();
    const [isProd, setIsProd] = useState(true);

    useEffect(() => {
        if (data) {
            sessionStorage.setItem('sessionToken', data.token);
            props.history.push('/');
        }
    }, [data, props.history]);

    const resetInputs = () => {
        resetUsername();
        resetPassword();
    };

    const handleSwitchAction = (event) => {
        setIsProd(event.target.checked);
    }

    const loginUser = (e) => {
        e.preventDefault();
        resetInputs();
        sessionStorage.setItem('isProduction', isProd.toString());
        handleNexusRequest('auth', {username, password})
    }

    return (
        <div className="login">
            <h1>Login</h1>
            <div>
                {error && <ErrorNotification errorMessage={error}/>}
                <input type='text' placeholder='Username' {...bindUsername}/>
                <input type='password' placeholder='Password' {...bindPassword}/>
                <Switch text="prod" id="envSwitch" defaultValue={true} action={handleSwitchAction}/>
                <Button text='Let me in.' action={loginUser}/>
            </div>
        </div>
    );
}

export default withRouter(Login);