import { useReducer, useCallback } from 'react';
import FileDownload from 'js-file-download';
import {apiUrl} from '../config/apiConfig';
import {isProductionEnvironment} from "../helpers/isProductionEnvironment";

const initialState = {
    error: null,
    data: null,
    successMessage: null
};

const handleFetchError = async (response, dispatchFunction) => {
    const data = await response.json();
    dispatchFunction({type: 'ERROR', errorMessage: data.message});
    if (response.status === 401 && data.message !== 'Bad credentials!') {
        sessionStorage.removeItem('sessionToken');
        window.location.href = '/';
    } else {
        console.log(data.message);
    }
}

const httpReducer = (curHttpState, action) => {
    switch (action.type) {
        case 'SEND':
            window.dispatchEvent(new CustomEvent('loader', {detail: {loaderShow: true}}));
            return initialState;
        case 'INFO':
            console.log("DATA ", action.data);
            window.dispatchEvent(new CustomEvent('loader', {detail: {loaderData: action.data}}));
            return initialState;
        case 'STREAM':
            window.dispatchEvent(new CustomEvent('loader', {detail: {loaderData: action.data}}));
            return curHttpState;
        case 'RESPONSE':
            window.dispatchEvent(new CustomEvent('loader', {detail: {loaderShow: false}}));
            return {
                ...curHttpState,
                data: action.responseData
            }
        case 'EMPTY-RESPONSE':
            window.dispatchEvent(new CustomEvent('loader', {detail: {loaderShow: false}}));
            return {
                ...curHttpState,
                data: null,
                estimatedTime: action.data.estimatedTime
            }
        case 'ERROR':
            window.dispatchEvent(new CustomEvent('loader', {detail: {loaderShow: false}}));
            return { error: action.errorMessage };
        case 'CLEAR':
            window.dispatchEvent(new CustomEvent('loader', {detail: {loaderShow: false}}));
            return initialState;
        default:
            throw new Error('Invalid action type!');
    }
};

const useHttp = () => {
    const [httpState, dispatchHttp] = useReducer(httpReducer, initialState);

    const clear = useCallback(() => {dispatchHttp({type: 'CLEAR'})}, []);

    const handleFetchResponse = useCallback(async (response) => {
        if (!response.ok) {
            await handleFetchError(response, dispatchHttp);
            return;
        }
        if (response.headers.get('file-name')) {
            const blob = await response.blob();
            FileDownload(blob, response.headers.get('file-name'));
            dispatchHttp({
                type: 'EMPTY-RESPONSE',
                data: {
                    message: 'File downloaded'
                }
            });
            if (response.headers.get('unFetchedItems')) {
                alert(`Bad/UnExisting ids: ${response.headers.get('unFetchedItems')}`);
            }
        } else if(response.headers.get('stream')) {
            const reader = response.body.getReader();
            await handleStream(reader);
        } else {
            const content = await response.json();
            dispatchHttp({
                type: 'RESPONSE',
                responseData: content
            });
        }
    }, []);

    const handleStream = async (streamReader) => {
        let currentData = '';
        let parsedData = null;
        while (true) {
            const { value, done } = await streamReader.read();
            if (!done) {
                const string = new TextDecoder().decode(value);
                try {
                    parsedData = JSON.parse(string);
                    dispatchHttp({type: 'STREAM', data: parsedData});
                } catch (e) {
                    currentData += string;
                }
            } else {
                dispatchHttp({
                    type: 'RESPONSE',
                    responseData: currentData || parsedData
                });
                break;
            }
        }
    }

    const handleNexusRequest = useCallback(async (path, data) => {
        dispatchHttp({type: 'SEND'});
        // const res = await fetch(`${apiUrl}/request/profile`, {
        //     headers: {
        //         "Content-Type": "application/json",
        //         Authorization: sessionStorage.getItem('sessionToken'),
        //         Production: isProductionEnvironment()
        //     },
        //     method: 'POST',
        //     body: JSON.stringify({...data, path})
        // });
        // const content = await res.json();
        // if (content.estimatedTime) {
        //     dispatchHttp({type: 'INFO', data: content});
        // }
        const response = await fetch(`${apiUrl}/${path}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: sessionStorage.getItem('sessionToken'),
                Production: isProductionEnvironment()
            },
            method: 'POST',
            body: JSON.stringify(data)
        });
        await handleFetchResponse(response);
    }, [handleFetchResponse]);

    const sendFileRequest = useCallback(async (data) => {
        dispatchHttp({type: 'SEND'});
        const res = await fetch(`${apiUrl}/file/profile`, {
            headers: {
                Authorization: sessionStorage.getItem('sessionToken'),
                Production: isProductionEnvironment()
            },
            method: 'POST',
            body: data
        });
        const content = await res.json();
        if (content.estimatedTime) {
            dispatchHttp({type: 'INFO', data: content});
        }
        const response = await fetch(`${apiUrl}/file`, {
            headers: {
                Authorization: sessionStorage.getItem('sessionToken'),
                Production: isProductionEnvironment()
            },
            method: 'POST',
            body: data
        });
        await handleFetchResponse(response);
    }, [handleFetchResponse]);

    return {
        data: httpState.data,
        clear,
        sendFileRequest,
        handleNexusRequest,
        error: httpState.error,
        message: httpState.message
    }
}

export default useHttp;