import {Fragment, useEffect, useState} from 'react';
import './Loader.scss';

const Loader = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);
    const [numberOfProcessedItems, setNumberOfProcessedItems] = useState(0);
    const [message, setMessage] = useState('');
    // const [timeRemaining, setTimeRemaining] = useState(0);

    const handleEvent = (event) => {
        if(event.detail && event.detail.loaderData && event.detail.loaderData.estimatedTime === "unknown") {
            setShowLoader(true);
            setMessage("Unknown estimation time, please be patient")
        }
        else if(event.detail.loaderShow !== undefined) {
            setShowLoader(event.detail.loaderShow);
            setTotalNumberOfItems(0);
            setNumberOfProcessedItems(0);
            setMessage('');
        } else if(event.detail.loaderData && event.detail.loaderData.totalNumberOfItems !== undefined && event.detail.loaderData.numberOfProcessedItems !== undefined) {
            setTotalNumberOfItems(event.detail.loaderData.totalNumberOfItems)
            setNumberOfProcessedItems(event.detail.loaderData.numberOfProcessedItems)
        }
        // else if (event.detail.loaderData.estimatedTime && event.detail.loaderData.estimatedTime !== "unknown") {
        //     setTimeRemaining(event.detail.loaderData.estimatedTime);
        // }
    }

    useEffect(() => {
        window.addEventListener('loader', handleEvent);

        return () => {
            window.removeEventListener('loader', handleEvent);
        }
    },[]);

    // useEffect(() => {
    //     let timer = null;
    //     if (timeRemaining && !timer) {
    //         timer = setInterval(() => {
    //             setTimeRemaining((state) => {
    //                 if (!state) return 0;
    //                 return state - 1;
    //             });
    //             const localMessage = timeRemaining ? `Estimated time remaining is ~ ${timeRemaining} s` : 'Finalizing...';
    //             setMessage(localMessage);
    //         }, 1000);
    //     }
    //     return () => clearInterval(timer);
    // }, [timeRemaining]);

    const calculateRemainingTime = () => {
        const minutes = Math.floor(((totalNumberOfItems - numberOfProcessedItems) * 0.8) / 60);
        const seconds = Math.ceil(((totalNumberOfItems - numberOfProcessedItems) * 0.8) % 60);
        return {
            minutes,
            seconds
        }
    }

    return <Fragment>
        {showLoader && (
            <div className="loader">
                <div className="loading">
                    Loading&#8230;
                </div>
                {totalNumberOfItems && numberOfProcessedItems && <div>
                    {/*<p className="timeLeft">Time left: {calculateRemainingTime().minutes} min {calculateRemainingTime().seconds} sec</p>*/}
                    <p className="progress">
                        <span>Progress: {numberOfProcessedItems} / {totalNumberOfItems}</span>
                    </p>
                </div>}
                {message && <div>
                    <p className="single progress">
                        <span>{message}</span>
                    </p>
                </div>}
            </div>
        )}
    </Fragment>
};

export default Loader;