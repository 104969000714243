import {Fragment, useState, useEffect} from 'react';
import Switch from "../Switch/Switch";
import RawFormInput from "../RawFormInput/RawFormInput";
import FileUpload from "../FileUpload/FileUpload";
import useHttp from '../../hooks/useHttp';

const RawFileForm = (props) => {
    const {entity, method, handleResponseFunction} = props;

    const [showRawForm, setShowRawForm] = useState(false);

    const {
        sendFileRequest,
        handleNexusRequest,
        data
    } = useHttp();

    useEffect(() => {
        if (data) {
            handleResponseFunction(data);
        }
    }, [data, handleResponseFunction])

    const handleImportTypeToggle = (event) => {
        setShowRawForm(event.target.checked);
    }

    const handleRawForm = async (rawData) => {
        await handleNexusRequest(`entity/${method}`, rawData);
    }

    const handleFileUpload = async (rawData) => {
        await sendFileRequest(rawData);
    }

    return (
        <Fragment>
            <Switch text="Raw Input" id="raw-input" action={handleImportTypeToggle} />
            {
                showRawForm ?
                    <RawFormInput entity={entity} sendRawDataAction={handleRawForm}/> :
                    <FileUpload entity={entity} method={method} fileUploadFunction={handleFileUpload} defaultValue={false}/>
            }
        </Fragment>
    )
}

export default RawFileForm;