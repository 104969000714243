import React, {Fragment} from 'react';
import './SuccessNotification.scss'

const SuccessNotification = (props) => (
    <Fragment>
        {props.successMessage && <div className="successNotification">
            {props.successMessage}
        </div>}
    </Fragment>
);

export default SuccessNotification;