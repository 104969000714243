const entityTemplatesConfig = {
    'deal': {
        json: [{
            fileName: 'deal-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'deal-example',
            displayName: 'template'
        }]
    },
    'placement': {
        json: [{
            fileName: 'placement-example',
            displayName: 'template'
        }],
        xls: [
            {
                fileName: 'placement-example',
                displayName: 'template'
            },
            {
                fileName: 'video-placement-example',
                displayName: 'video template'
            }
        ]
    },
    'publisher': {
        json: [{
            fileName: 'publisher-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'publisher-example',
            displayName: 'template'
        }]
    },
    'site': {
        json: [{
            fileName: 'site-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'site-example',
            displayName: 'template'
        }]
    },
    'creative': {
        json: [{
            fileName: 'creative-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'creative-example',
            displayName: 'template'
        }]
    },
    'profile': {
        json: [{
            fileName: 'creative-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'creative-example',
            displayName: 'template'
        }]
    },
    'content-category': {
        json: [{
            fileName: 'content-category-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'content-category-example',
            displayName: 'template'
        }]
    },
    'placement-set': {
        json: [{
            fileName: 'placement-set-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'placement-set-example',
            displayName: 'template'
        }]
    },
    'media-subtype': {
        json: [{
            fileName: 'media-subtype-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'media-subtype-example',
            displayName: 'template'
        }]
    },
    'line-item': {
        json: [{
            fileName: 'line-item-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'line-item-example',
            displayName: 'template'
        }]
    },
    'campaign': {
        json: [{
            fileName: 'campaign-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'campaign-example',
            displayName: 'template'
        }]
    },
    'change-log': {
        json: [{
            fileName: 'change-log-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'change-log-example',
            displayName: 'template'
        }]
    },
    'change-log-detail': {
        json: [{
            fileName: 'change-log-detail-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'change-log-detail-example',
            displayName: 'template'
        }]
    },
    'keyvalue': {
        json: [{
            fileName: 'key-value-detail-example',
            displayName: 'template'
        }],
        xls: [{
            fileName: 'key-value-detail-example',
            displayName: 'template'
        }]
    }
}

export {entityTemplatesConfig};