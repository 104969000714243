import {useState, useEffect, Fragment, useCallback} from 'react';
import Select from "react-select";
import Button from '../Button/Button';
import useHttp from '../../hooks/useHttp';
import Switch from '../Switch/Switch';
import Modal from '../Modal/Modal';
import './FormRenderer.scss';
import InputModal from "../InputModal/InputModal";
import RawFileForm from "../RawFileForm/RawFileForm";
import FileUpload from "../FileUpload/FileUpload";
import SuccessNotification from "../Notifications/SucessNotification/SuccessNotification";

const FormRenderer = (props) => {
    const {availableInputs, entity, method} = props;
    const [inputs, setInputs] = useState({});
    const [defaultFormType, setDefaultFormType] = useState(false);
    const [excelReport, setExcelReport] = useState(false);
    const [getAllItems, setGetAllItems] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [showInputModal, setShowInputModal] = useState(false);
    const [rawFormResponse, setRawFormResponse] = useState(null);

    const {
        data,
        sendFileRequest,
        handleNexusRequest,
        clear,
        message
    } = useHttp();

    useEffect(() => {
        setInputs({});
        setShowResponse(false);
        setRawFormResponse(false);
        setExcelReport(false);
        clear();

        if (['get'].includes(method)) {
            setDefaultFormType(true);
        } else {
            setDefaultFormType(false);
        }

    }, [availableInputs, method, clear]);

    useEffect(() => {
        if (data) {
            setShowResponse(true);
        }
    }, [data])

    const customSelectStyles = {
        menu: (provided, _) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: 'black',
            padding: 10,
            textAlign: "left"
        }),
        multiValue: (styles, _) => {
            const color = "#4a77d4";
            return {
                ...styles,
                backgroundColor: color,
                borderRadius: "5px",
                padding: "5px",
                textAlign: "left"
            };
        }
    }
    
    const onInputChange = (event) => {
        setInputs((prevState) => {
            const state =  {
                ...prevState,
            };
            if (event.property) {
                state[event.property] = event.value;
            } else {
                state[event.target.name] = event.target.value;
            }
            return state;
        })
    }

    const handleResponseTypeToggle = (event) => {
        setExcelReport(event.target.checked);
    }

    const handleGetAllItemsToggle = (event) => {
        setGetAllItems(event.target.checked);
    }

    const handleForm = async () => {
        setShowResponse(false);
        const url = excelReport ? `entity/${method}/xls` : `entity/${method}`;
        await handleNexusRequest(url, {...inputs, entity, all_items: getAllItems});
    }

    const displayInputModal = () => setShowInputModal(true);

    const inputModalAction = (data) => {
        setInputs((prevState) => {
            const state =  {
                ...prevState,
            };
            state['ids'] = data;
            return state;
        });
        setShowInputModal(false);
    }

    const handleGetFileRequest = (data) => {
        setShowResponse(false);
        sendFileRequest(data);
    }
    
    const handleRawFileResponseFunction = useCallback((rawFormDataResponse) => {
        setRawFormResponse(rawFormDataResponse);
        setShowResponse(true);
    }, [])

    return (
       <Fragment>
           <div className="formRenderer">
               <SuccessNotification successMessage={message}/>
               {
                   defaultFormType ?
                       <Fragment>
                           {method === 'get' && <Fragment>
                               <Switch text="Excel report" id="excel-report" action={handleResponseTypeToggle} defaultValue={false}/>
                               {["placement", "publisher", "site"].includes(entity) && <Switch text="Get all items" id="get-all-items" action={handleGetAllItemsToggle} defaultValue={false}/>}
                               {entity !== 'change-log' && <Fragment>
                                   <FileUpload fileUploadFunction={handleGetFileRequest}
                                               entity={entity} method={method} fileNameAddition={excelReport ? "xls" : false}/>
                                   <div className="separator">----- <span>OR</span> -----</div>
                               </Fragment>}
                           </Fragment>}
                           {availableInputs.map( input => {
                               // currently only in case of "change-log" entity since here we need to have a set of predefined options
                               if (Object.keys(input).length && input.type === "select") {
                                   return <Select
                                       key={`${input}-select`}
                                       name="presets"
                                       placeholder={input.name}
                                       styles={customSelectStyles}
                                       options={input.values.map(item => ({value: item, label: item, property: input.name}))}
                                       className="selectCustomClassWrapper"
                                       classNamePrefix="select"
                                       onChange={onInputChange}
                                   />
                               }
                               else if (input === 'ids') {
                                   return (
                                       <div key={`${input}-div`}>
                                           <div className="expanderDiv" key={`${input}-expander`}>
                                               <span onClick={displayInputModal}>expand</span>
                                           </div>
                                           <textarea className="formInput" name={input} value={inputs['ids']} key={input} onChange={onInputChange} placeholder={input} rows={1}/>
                                       </div>
                                   )
                               } else {
                                   return <textarea className="formInput" name={input} key={input} onChange={onInputChange} placeholder={input} rows={1}/>
                               }
                           })}
                           <Button text="Submit" action={handleForm}/>
                       </Fragment> :
                       <RawFileForm entity={entity} method={method} handleResponseFunction={handleRawFileResponseFunction} />
               }
               <p>Need more help with the params? Check the <a
                   href={`https://docs.xandr.com/bundle/xandr-api/page/${entity}-service.html`} rel="noreferrer" target="_blank">XANDR wiki</a>
               </p>
           </div>
           <InputModal displayModal={showInputModal} text={inputs.ids} modalAction={inputModalAction} label="Ids:" />
           <Modal displayModal={showResponse} text={JSON.stringify(data || rawFormResponse, null, 4)}/>
       </Fragment>
    )
};

export default FormRenderer;