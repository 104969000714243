import React, {useState} from 'react';
import Button from '../Button/Button';
import './FileUpload.scss';
import {entityTemplatesConfig} from "../../config/entityTemplatesConfig";

const FileUpload = (props) => {
    const {REACT_APP_FILES_STORAGE_URL} = process.env;
    const {entity, method, fileUploadFunction, hideTemplate, fileNameAddition} = props;
    const [selectedFile, setSelectedFile] = useState('');

    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    }

    const buildFileName = () => {
        const fileName = `${entity}-${method}`;
        return fileNameAddition ?  `${fileName}-${fileNameAddition}` : fileName;
    }

    const onFileUpload = () => {
        const formData = new FormData();

        formData.append(
            buildFileName(),
            selectedFile,
            selectedFile.name
        );

        fileUploadFunction(formData);
    };

    return (
        <div className="fileUpload" data-testid="fileUpload">
            <h3>Upload file:</h3>
            <input type="file" onChange={onFileChange} data-testid="uploadField" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
            {!hideTemplate && <p>Need help with the file format? Download the entity method {entityTemplatesConfig[entity]['xls'].map(item =>
                <a
                    key={`${item.fileName}.json`}
                    href={`${REACT_APP_FILES_STORAGE_URL}/${item.fileName}.xlsx`}
                    target="_blank"
                    data-testid="templateLink"
                    rel="noreferrer">
                    <span className="templateName">{item.displayName}</span>
                </a>)}
            </p>}
            <Button action={onFileUpload} text="Upload" />
        </div>
    );
};

export default FileUpload;