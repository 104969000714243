import {Fragment, useState, useEffect} from 'react';
import './InputModal.scss';
import Button from "../Button/Button";

const InputModal = (props) => {
    const {text, displayModal, modalAction, label} = props;

    useEffect(() => {
        setShowModal(displayModal);
    }, [displayModal]);

    const [showModal, setShowModal] = useState(false);
    const [input, setInput] = useState(text);

    const handleChange = (event) => {
        setInput(event.target.value);
    }
    
    const submitModal = () => {
        modalAction(input);
    }

    return (
        <Fragment>
            {showModal && <div className="inputModal">
                <div className="content">
                    <label>
                        {label}
                    </label>
                    <textarea defaultValue={text} onChange={handleChange}/>
                    <Button text={"SAVE"} action={submitModal} />
                </div>
            </div>}
        </Fragment>
    )
};

export default InputModal;