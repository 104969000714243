import {useState, Fragment} from 'react';
import {entityConfiguration} from "../../config/entityConfig";
import MethodPicker from "../MethodPicker/MethodPicker";
import './EntityPicker.scss'

const EntityPicker = () => {
    const [entityConfig, setEntityConfig] = useState({'Select entity': '', ...entityConfiguration});
    const [selectedEntity, setSelectedEntity] = useState('');

    const handleClick = (event) => {
        if (entityConfig.hasOwnProperty('Select entity')) {
            setEntityConfig((prevState) => {
                const tempEntityObject = {...prevState};
                delete tempEntityObject['Select entity'];
                return tempEntityObject;
            });
        }
        const key = event.target.value;
        setSelectedEntity(key);
    };

    return (
        <Fragment>
            <div className="select">
                <select name="entity" id="entity" onChange={handleClick} data-testid="entitySelect">
                    {Object.keys(entityConfig).map(key => <option value={key} key={key}>{key}</option>)}
                </select>
            </div>
            {selectedEntity && <div className="methodsHolder" data-testid="methodsHolder">
                <MethodPicker availableMethods={entityConfig[selectedEntity]} entity={selectedEntity}/>
            </div>}
        </Fragment>
    );
};

export default EntityPicker;