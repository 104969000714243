import {useState, useEffect, Fragment} from 'react';
import FormRenderer from '../FormRenderer/FormRenderer';
import './MethodPicker.scss';

const MethodPicker = (props) => {
    const {availableMethods, entity} = props;
    const getDefaultMethod = () => availableMethods['get'] ? 'get' : Object.keys(availableMethods)[0];
    const [selectedMethod, setSelectedMethod] = useState(getDefaultMethod());
    const [availableInputs, storeAvailableInputs] = useState(availableMethods[getDefaultMethod()]);


    const handleChange = (e) => {
        setSelectedMethod(e.target.value);
        storeAvailableInputs(availableMethods[e.target.value]);
    }

    useEffect(() => {
        setSelectedMethod(getDefaultMethod());
        storeAvailableInputs(availableMethods[getDefaultMethod()]);
    }, [availableMethods, entity])
    
    return (
        <Fragment>
            <div className='methodPicker' onChange={handleChange}>
                {Object.keys(availableMethods).map((key, index) =>
                    <span key={key}>
                        <input key={`${key}-${index}`} className="checkbox-tools" type="radio" name="method" value={key} id={`${key}-${index}`} defaultChecked={key===selectedMethod} />
                    <label key={`label-${key}-${index}`} className="for-checkbox-tools" htmlFor={`${key}-${index}`}>
                        {key}
                    </label>
                    </span>
                )}
            </div>
            <div className='methodPicker'>
                {availableInputs && <FormRenderer availableInputs={availableInputs} entity={entity} method={selectedMethod}/>}
            </div>
        </Fragment>
    );
};

export default MethodPicker;